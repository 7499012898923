import React from "react"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import { Container } from "../components/Container"
import TagBadge from "../components/TagBadge"
import ReactPaginate from "react-paginate"

const BlogCard: React.FC<{ blogPost: BlogPostProps }> = ({ blogPost }) => {
  return (
    <div
      key={blogPost.title}
      className="flex flex-col overflow-hidden rounded-lg shadow-lg"
    >
      <div className="flex-shrink-0">
        <img
          className="h-48 w-full object-cover"
          src={blogPost.imageUrl}
          alt=""
        />
      </div>
      <div className="flex flex-1 flex-col justify-between bg-white p-6">
        <div className="flex-1">
          <a href={`/blogs/${blogPost.slug}`} className="mt-2 block">
            <p className="text-xl font-semibold text-gray-900">
              {blogPost.title}
            </p>
            <p className="mt-3 text-base text-gray-500">{blogPost.body}</p>
          </a>
        </div>

        {blogPost.tags.length > 0 && (
          <div className="flex gap-1 mt-4">
            {blogPost.tags.map((tag, index) => (
              <TagBadge key={`blogpost-${blogPost.id}-tag-${index}`}>
                {tag}
              </TagBadge>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

type BlogPostProps = {
  id: string
  title: string
  body: string
  tags: string[]
  imageUrl: string
  slug: string
  postedBy: string
  createdAt: string
}

interface BlogsPageProps {
  currentUser: object
  blogPosts: BlogPostProps[]
  paginationInfo: PaginationInfoProps
}

type PaginationInfoProps = {
  total_count: number | null
  total_pages: number | null
  current_page: number | null
  next_page: number | null
  prev_page: number | null
  limit_value: number | null
}

const BlogsPage: React.FC<BlogsPageProps> = ({
  currentUser,
  blogPosts,
  paginationInfo
}) => {
  return (
    <Layout currentUser={currentUser}>
      <Container className="py-10">
        <PageHeader
          label="Blog Posts"
          description="We believe in knowledge sharing in the SEO space. Read about our team's write ups on SEO tips & tricks."
        />

        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {blogPosts.map((blogPost, index) => (
            <BlogCard blogPost={blogPost} key={`blog-post-${index}`} />
          ))}
        </div>

        {paginationInfo.total_count > 0 && (
          <div className="flex justify-center py-6">
            <ReactPaginate
              className="inline-flex divide-x divide-gray-300 border border-gray-300 rounded"
              pageClassName="flex justify-center items-center"
              pageLinkClassName="px-4 py-2 hover:bg-gray-100"
              activeClassName="bg-blue-300/20 text-blue-600 hover:bg-blue-300/20"
              previousClassName="flex justify-center items-center"
              previousLinkClassName="px-4 py-2"
              nextClassName="flex justify-center items-center"
              nextLinkClassName="px-4 py-2"
              disabledClassName="bg-gray-100 hover:bg-gray-100 text-gray-300"
              onClick={e => {
                window.location.href = `/blogs?page=${e.nextSelectedPage + 1}`
              }}
              pageRangeDisplayed={5}
              pageCount={+paginationInfo.total_pages}
              breakLabel="..."
              initialPage={+paginationInfo.current_page - 1}
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </Container>
    </Layout>
  )
}

export default BlogsPage
