import React from "react"
import { Header } from "./Header"
import { Footer } from "./Footer"

const Layout = ({ children, currentUser }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header currentUser={currentUser} />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
