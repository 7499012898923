import React from "react"
import Layout from "../components/Layout"
import { Hero } from "../components/Hero"
import { PrimaryFeatures } from "../components/PrimaryFeatures"
import { SecondaryFeatures } from "../components/SecondaryFeatures"
import { CallToAction } from "../components/CallToAction"
import { Pricing } from "../components/Pricing"
import { Faqs } from "../components/Faqs"

const HomePage = ({ currentUser }) => {
  return (
    <Layout currentUser={currentUser}>
      <Hero currentUser={currentUser} />
      <PrimaryFeatures />
      <SecondaryFeatures />
      <CallToAction />
      <Pricing currentUser={currentUser} />
      <Faqs />
    </Layout>
  )
}

export default HomePage
