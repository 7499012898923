import React from "react"
import clsx from "clsx"
import { Container } from "./Container"

// Features images
import dailyRankUpdatesImg from "../assets/images/secondary-features/daily_rank_updates.svg"
import shareviewImg from "../assets/images/secondary-features/shareview.svg"
import whiteLabelReportsImg from "../assets/images/secondary-features/white_label_reports.svg"
import keywordResearchModuleImg from "../assets/images/secondary-features/keyword_research_module.svg"
import reputable3rdPartyDataImg from "../assets/images/secondary-features/reputable_3rd_party_data.svg"
import subUsersImg from "../assets/images/secondary-features/subusers.svg"
import campaignTrackingImg from "../assets/images/secondary-features/campaign_tracking.svg"
import competitorResearchImg from "../assets/images/secondary-features/competitor_research.svg"
import rankChangeAlertsImg from "../assets/images/secondary-features/rank_change_alerts.svg"

const features = [
  {
    name: "Daily Rank Updates",
    description:
      "Google ranks are updated twice daily in 12-hour intervals with on-demand updates available.",
    icon: dailyRankUpdatesImg
  },
  {
    name: "ShareView",
    description: (
      <>
        Share your campaign to your clients or team with just a single link.
        Choose between{" "}
        <a
          className="text-blue-500 hover:text-blue-600 hover:underline"
          href={
            "https://www.serpmarker.com/shareview/v2/keywords/QEVuQwEAko8zwCEHc%2F4%2FJXQL3coi3Q=="
          }
          target="_blank"
        >
          public
        </a>{" "}
        or{" "}
        <a
          className="text-blue-500 hover:text-blue-600 hover:underline"
          href={
            "https://www.serpmarker.com/shareview/v2/keywords/QEVuQwEAPwVMoup7dMn4N87iGzV%2Fzw=="
          }
          target="_blank"
        >
          hidden
        </a>{" "}
        details.
      </>
    ),
    icon: shareviewImg
  },
  {
    name: "White Label Reports",
    description: (
      <>
        Label campaign and audit reports using your own company logo and
        branding.{" "}
        <a
          className="text-blue-500 hover:text-blue-600 hover:underline"
          href="https://dt2tg12evjgp9.cloudfront.net/assets/sample-reports-v4/project-report-Oct_23_201820181023-393-1gc6b0q.pdf"
          target="_blank"
        >
          Rank report sample
        </a>
        .
      </>
    ),
    icon: whiteLabelReportsImg
  },
  {
    name: "Keywords Research Module",
    description:
      "Look for profitable keywords & suggestions for your projects with our Keyword Finder Tool.",
    icon: keywordResearchModuleImg
  },
  {
    name: "Reputable 3rd-Party Data",
    description:
      "Besides Google search data, we also provide Moz, Majestic and SEMRush data at no extra cost.",
    icon: reputable3rdPartyDataImg
  },
  {
    name: "Sub-Users",
    description:
      "Create and assign sub-accounts to your clients or team with customizable permission & access.",
    icon: subUsersImg
  },
  {
    name: "Campaign Tracking",
    description: "Track and monitor how your SEO campaign is performing.",
    icon: campaignTrackingImg
  },
  {
    name: "Competitor Research",
    description:
      "Monitor your competitor's website and keyword stats and see how you compare to them.",
    icon: competitorResearchImg
  },
  {
    name: "Rank Change Alerts",
    description:
      "Get notified through email when your campaign's rank changes. Rank change notification are customisable.",
    icon: rankChangeAlertsImg
  }
]

function Feature({ feature, isActive, className, ...props }) {
  return (
    <div
      className={clsx(
        "bg-gray-100 rounded-lg px-6 py-4 flex flex-col items-center justify-center",
        className,
        !isActive && "opacity-75 hover:opacity-100"
      )}
      {...props}
    >
      <img
        className="w-[200px]"
        src={feature.icon}
        alt={`feature ${feature.name}`}
      />
      <h3 className="mt-6 font-semibold text-xl text-gray-900">
        {feature.name}
      </h3>
      <p className="mt-2 text-base text-gray-600">{feature.description}</p>
    </div>
  )
}

function FeaturesMobile() {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map(feature => (
        <div key={feature.name}>
          <Feature feature={feature} className="mx-auto max-w-3xl" isActive />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6" />
            <div className="relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
              {feature.image}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const FeaturesDesktop = () => {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-10">
      {features.map(feature => (
        <Feature
          key={feature.name}
          feature={{
            ...feature,
            name: feature.name
          }}
          isActive
          className="relative"
        />
      ))}
    </div>
  )
}

export function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for simplifying everyday business tasks"
      className="pt-20 pb-14 sm:pb-20 sm:pt-32 lg:pb-32"
    >
      <Container>
        <div className="mx-auto max-w-3xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-gray-900 sm:text-4xl">
            Values with your{" "}
            <span className="text-blue-600 font-semibold">SerpMarker </span>
            Subscription
          </h2>
          <p className="mt-4 text-lg tracking-tight text-gray-700">
            Comes with all the help you need to track your SEO performance!
          </p>
        </div>
        <FeaturesDesktop />
      </Container>
    </section>
  )
}
