import React from "react"
import { Container } from "./Container"
import { Logo } from "./Logo"

import facebookIcon from "../assets/images/social-icons/facebook.png"
import twitterIcon from "../assets/images/social-icons/twitter.png"
import youtubeIcon from "../assets/images/social-icons/youtube.png"

interface FooterLinkProps {
  name: string
  url: string
  target?: string
}

const links: FooterLinkProps[] = [
  { name: "Features", url: "/#features" },
  { name: "Pricing", url: "/#pricing" },
  { name: "Blog", url: "/blogs" },
  {
    name: "Support Center",
    url: "https://serpmarker2.freshdesk.com/",
    target: "_blank"
  },
  { name: "Privacy Policy", url: "/privacy-policy" },
  { name: "Terms of Service", url: "/terms-of-service" }
]

const socialLinks: (FooterLinkProps & { image: string })[] = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/Serpmarkercom-1287619591352360/",
    image: facebookIcon
  },
  {
    name: "Twitter",
    url: "https://twitter.com/serpmarker",
    image: twitterIcon
  },
  {
    name: "Youtube",
    url: "https://www.youtube.com/watch?v=ybehuLhQUCY",
    image: youtubeIcon
  }
]

export function Footer() {
  return (
    <footer className="bg-slate-50 mt-auto">
      <Container>
        <div className="py-16">
          <a href="/">
            <Logo className="mx-auto h-10 w-auto" />
          </a>
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 flex justify-center gap-6 flex-wrap">
              {links.map(({ name, url, target }, index) => (
                <a key={`footer-link-${index}`} href={url} target={target}>
                  {name}
                </a>
              ))}
            </div>
          </nav>
        </div>

        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <div className="flex gap-x-6">
            {socialLinks.map(({ name, url, image }, index) => (
              <a
                key={`footer-social-${index}`}
                href={url}
                className="group"
                aria-label={name}
              >
                <img alt={name} src={image} className="h-6 w-6" />
              </a>
            ))}
          </div>
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            Copyright &copy; 2016 - {new Date().getFullYear()} | SerpMarker by
            Qurious Web LLP.
          </p>
        </div>
      </Container>
    </footer>
  )
}
