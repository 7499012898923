import React from "react"
import { parseISO, format } from "date-fns"
import Layout from "../components/Layout"
import { Container } from "../components/Container"
import TagBadge from "../components/TagBadge"

const BlogDetailsPage = ({ currentUser, blogPost }) => {
  return (
    <Layout currentUser={currentUser}>
      <Container>
        <div className="relative overflow-hidden bg-white py-16">
          <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
            <div
              className="relative mx-auto h-full max-w-prose text-lg"
              aria-hidden="true"
            >
              <svg
                className="absolute top-12 left-full translate-x-32 transform"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                />
              </svg>
              <svg
                className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                />
              </svg>
              <svg
                className="absolute bottom-12 left-full translate-x-32 transform"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                />
              </svg>
            </div>
          </div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-prose text-lg">
              <h1>
                <span className="block text-center text-lg font-semibold text-indigo-600">
                  <a href="/blogs">Blogs</a> > SEO
                </span>
                <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                  {blogPost.title}
                </span>
                <span className="block text-center text-sm font-light text-gray-400 mt-2">
                  Posted on{" "}
                  {format(parseISO(blogPost.createdAt), "MMM dd, yyyy")} by{" "}
                  {blogPost.postedBy}
                </span>
              </h1>
            </div>

            <img
              className="w-full rounded-lg my-10 mx-auto"
              src={blogPost.imageUrl}
              alt=""
            />

            <div
              className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-600 blogpost-details"
              dangerouslySetInnerHTML={{ __html: blogPost.body }}
            />

            {blogPost.tags.length > 0 && (
              <div className="flex gap-2 mt-6 items-center">
                <span className="text-gray-400 mr-2">Tags: </span>
                {blogPost.tags.map((tag, index) => (
                  <TagBadge key={`blogpost-${blogPost.id}-tag-${index}`}>
                    {tag}
                  </TagBadge>
                ))}
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default BlogDetailsPage
