import React from "react"
import { Button } from "./Button"

const SupportCTA = ({ title }) => {
  return (
    <div className="py-10 flex justify-center items-center gap-2 flex-col">
      <p>{title}</p>
      <Button href="https://serpmarker2.freshdesk.com/" target="_blank">
        Ask us!
      </Button>
    </div>
  )
}

export default SupportCTA
