import ReactOnRails from "react-on-rails"
import HomePage from "../bundles/pages/HomePage"
import PrivacyPolicyPage from "../bundles/pages/PrivacyPolicyPage"
import TermsOfServicePage from "../bundles/pages/TermsOfServicePage"
import BlogsPage from "../bundles/pages/BlogsPage"
import BlogDetailsPage from "../bundles/pages/BlogDetailsPage"

ReactOnRails.register({
  HomePage,
  PrivacyPolicyPage,
  TermsOfServicePage,
  BlogsPage,
  BlogDetailsPage
})
