import React from "react"
import clsx from "clsx"

interface PageHeaderProps {
  className?: string
  label: string
  description?: string
}

const PageHeader: React.FC<PageHeaderProps> = ({
  className,
  label,
  description
}) => {
  return (
    <div className={clsx("mb-8", className)}>
      <h1 className="text-3xl font-bold mb-2">{label}</h1>
      {description && <span className="text-gray-400">{description}</span>}
    </div>
  )
}

export default PageHeader
