import React from "react"
import PageHeader from "../components/PageHeader"
import Layout from "../components/Layout"
import { Container } from "../components/Container"
import SupportCTA from "../components/SupportCTA"

const TermItem = ({ children }) => {
  return <div>{children}</div>
}

const Heading = ({ as, content }) => {
  const HeadingTag = as

  return <HeadingTag className="text-lg font-semibold mb">{content}</HeadingTag>
}

const TermsOfServicePage = ({ currentUser }) => {
  return (
    <Layout currentUser={currentUser}>
      <Container className="py-10">
        <PageHeader label="Terms of Service" />

        <div className="flex flex-col gap-8">
          <TermItem>
            <Heading as="h3" content="1. Terms" />
            <p>
              By accessing the website at{" "}
              <a
                className="text-blue-600 hover:underline"
                target="_blank"
                href="/"
              >
                https://www.serpmarker.com/
              </a>
              , you are agreeing to be bound by these terms of service, all
              applicable laws and regulations, and agree that you are
              responsible for compliance with any applicable local laws. If you
              do not agree with any of these terms, you are prohibited from
              using or accessing this site. The materials contained in this
              website are protected by applicable copyright and trademark law.
            </p>
          </TermItem>

          <TermItem>
            <Heading as="h3" content="2. Use License" />

            <ol>
              <li>
                Permission is granted to temporarily download one copy of the
                materials (information or software) on Qurious Web LLP's website
                for personal, non-commercial transitory viewing only. This is
                the grant of a license, not a transfer of title, and under this
                license you may not:
                <ol>
                  <li>modify or copy the materials;</li>
                  <li>
                    use the materials for any commercial purpose, or for any
                    public display (commercial or non-commercial);
                  </li>
                  <li>
                    attempt to decompile or reverse engineer any software
                    contained on Qurious Web LLP's website;
                  </li>
                  <li>
                    remove any copyright or other proprietary notations from the
                    materials; or
                  </li>
                  <li>
                    transfer the materials to another person or "mirror" the
                    materials on any other server.
                  </li>
                </ol>
              </li>

              <li>
                This license shall automatically terminate if you violate any of
                these restrictions and may be terminated by Qurious Web LLP at
                any time. Upon terminating your viewing of these materials or
                upon the termination of this license, you must destroy any
                downloaded materials in your possession whether in electronic or
                printed format.
              </li>
            </ol>
          </TermItem>

          <TermItem>
            <Heading as="h3" content="3. Disclaimer" />

            <ol>
              <li>
                The materials on Qurious Web LLP's website are provided on an
                'as is' basis. Qurious Web LLP makes no warranties, expressed or
                implied, and hereby disclaims and negates all other warranties
                including, without limitation, implied warranties or conditions
                of merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property or other violation of
                rights.
              </li>

              <li>
                Further, Qurious Web LLP does not warrant or make any
                representations concerning the accuracy, likely results, or
                reliability of the use of the materials on its website or
                otherwise relating to such materials or on any sites linked to
                this site.
              </li>
            </ol>
          </TermItem>

          <TermItem>
            <Heading as="h3" content="4. Limitations" />

            <p>
              In no event shall Qurious Web LLP or its suppliers be liable for
              any damages (including, without limitation, damages for loss of
              data or profit, or due to business interruption) arising out of
              the use or inability to use the materials on Qurious Web LLP's
              website, even if Qurious Web LLP or a Qurious Web LLP authorized
              representative has been notified orally or in writing of the
              possibility of such damage. Because some jurisdictions do not
              allow limitations on implied warranties, or limitations of
              liability for consequential or incidental damages, these
              limitations may not apply to you.
            </p>
          </TermItem>

          <TermItem>
            <Heading as="h3" content="5. Accuracy of materials" />

            <p>
              The materials appearing on Qurious Web LLP's website could include
              technical, typographical, or photographic errors. Qurious Web LLP
              does not warrant that any of the materials on its website are
              accurate, complete or current. Qurious Web LLP may make changes to
              the materials contained on its website at any time without notice.
              However Qurious Web LLP does not make any commitment to update the
              materials.
            </p>
          </TermItem>

          <TermItem>
            <Heading as="h3" content="6. Links" />

            <p>
              Qurious Web LLP has not reviewed all of the sites linked to its
              website and is not responsible for the contents of any such linked
              site. The inclusion of any link does not imply endorsement by
              Qurious Web LLP of the site. Use of any such linked website is at
              the user's own risk.
            </p>
          </TermItem>

          <TermItem>
            <Heading as="h3" content="7. Modifications" />

            <p>
              Qurious Web LLP may revise these terms of service for its website
              at any time without notice. By using this website you are agreeing
              to be bound by the then current version of these terms of service.
            </p>
          </TermItem>

          <TermItem>
            <Heading as="h3" content="8. Governing Law" />

            <p>
              These terms and conditions are governed by and construed in
              accordance with the laws of Kuala Lumpur, Malaysia and you
              irrevocably submit to the exclusive jurisdiction of the courts in
              that State or location.
            </p>
          </TermItem>
        </div>

        <SupportCTA title="Have a question regarding our terms of service?" />
      </Container>
    </Layout>
  )
}

export default TermsOfServicePage
