import React from "react"
import { Container } from "./Container"

const faqs = [
  [
    {
      question: "What can SerpMarker track?",
      answer:
        "We track Google, Bing and Yahoo SERPs by default for all new projects. We also track Google images, News and Local Listings (GMB). SerpMarker also does Google mobile and Youtube tracking too."
    },
    {
      question: "What are the third party metrics that SerpMarker display?",
      answer:
        "Besides SERPs, SerpMarker shows data from MOZ, Majestic and SEMRush for your URLs. Vital metrics such as Majestic’s Trust flow (TF), Citation flow (CF), Moz Page authority (PA), Domain Authority (DA), SEMrush rank, organic traffic and organic keywords are displayed too. SerpMarker also displays your URL’s rank and social signals too."
    }
  ],
  [
    {
      question:
        "I am an agency , is there any features that are suitable for us to scale and whitelabel?",
      answer:
        "Yes, SerpMarker offers customized white labelling for our audit and rankings reports. SerpMarker also has a sub-users function for agencies to share a master account with co-workers but sub-divide campaigns accordingly to peers."
    },
    {
      question: "Do you offer other Services besides rank tracking?",
      answer:
        "Yes, we have in house SEO packages tailored to every customer’s needs. Sign-up for a free account and login to see our packages now."
    }
  ],
  [
    {
      question:
        "I'm an existing customer with another rank tracker. Can I import and move over to SerpMarker?",
      answer:
        "Absolutely! We would be glad to help you import your data over to SerpMarker. Simply hit us up by contacting our 24/7 Helpdesk."
    },
    {
      question: "Can I try SerpMarker first?",
      answer:
        "Yes! We offer a 14 days free trial for all new users. Experience the full functions of SerpMarker. No credit card details required."
    }
  ]
]

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <Container className="relative">
        <div className="mx-auto max-w-full lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-gray-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-gray-700">
            If you can’t find what you’re looking for,{" "}
            <a
              href="https://serpmarker2.freshdesk.com/"
              className="text-blue-600 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              open a support ticket
            </a>{" "}
            with us and we&apos;ll get back to you!
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-3xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-8">
                {column.map((faq, faqIndex) => (
                  <li
                    key={faqIndex}
                    className="bg-gray-200/50 rounded-lg px-6 py-4"
                  >
                    <h3 className="font-semibold text-lg leading-7 text-gray-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-base text-gray-500">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
